<template>
  <ul class="d-flex flex-column justify-space-between text-center pl-0">
    <liNav
      title="Plan Comptable"
      module_name="parametrages_comptables__plan_comptable"
    />
    <liNav
      title="Type de facturation"
      module_name="parametrages_comptables__type_facturation"
    />
    <liNav
      title="Plan analytique"
      module_name="parametrages_comptables__plan_analytique"
    />
    <liNav
      title="Flux financier"
      module_name="parametrages_comptables__flux_financier"
    />
  </ul>
</template>

<script>
import liNav from "@/components/Common/Navigation__item";

export default {
  components: { liNav },

  data: () => ({}),
  created() {},

  computed: {},
};
</script>
