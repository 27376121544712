<template>
  <ul class="d-flex flex-column justify-space-between text-center pl-0">
    <liNav title="SEPA" module_name="export__sepa" auth />
    <liNav title="SAGE" module_name="export__sage" />
  </ul>
</template>

<script>
import liNav from "@/components/Common/Navigation__item";

export default {
  components: { liNav },

  data: () => ({}),
  created() {},

  computed: {},
};
</script>
