<template>
  <v-dialog
    v-model="openModal"
    @click:outside="$emit('onClose')"
    @keydown.esc="$emit('onClose')"
    max-width="80vw"
  >
    <v-card class="modal__voyage__detail">
      <v-toolbar class="toolbar-header" color="blue-dark" dark>
        {{
          `${context && context.NomFluxFinancier} (${
            context.STR_Structure && context.STR_Structure.NomStructure
          })`
        }}

        <v-icon class="close-icon" @click="$emit('onClose')">mdi-close</v-icon>
      </v-toolbar>
      <!-- <v-icon class="close-icon" @click="$emit('onClose')">mdi-close</v-icon> -->
      <v-data-table
        :headers="tabHeaders"
        :fixed-header="true"
        :loading="loading"
        :items="items"
        item-key="id"
        class="elevation-1 list__table"
      >
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- <v-btn
          color="blue-dark text-white font-weight-bold"
          rounded
          class="__full __icon-left"
          @click="$emit('onClose')"
        >
          Fermer
        </v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "FluxFinancierDetail",
  data: () => ({
    tabHeaders: [
      {
        text: "N° de flux",
        value: "id",
      },
      {
        text: "Date création",
        value: "creation",
      },

      // { text: "Structure", value: "STR_Structure.NomStructure" },

      {
        text: "Libellé",
        value: "NomFluxFinancierDetail",
      },

      { text: "PU", value: "PrixUnit" },
      { text: "Quantité", value: "Quantity" },
      { text: "Débit", value: "Credit" },
      { text: "Crédit", value: "Debit" },
    ],
  }),

  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    context: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("user", ["currentUserId", "getRole"]),
    items() {
      if (!this.context?.MKP_FluxFinancierDetail?.length) return [];
      // this.context?.MKP_FluxFinancierDetail?.map((fluxDetail) => {
      //   if (this.isSuperAdmin) {
      //     fluxDetail.debit = fluxDetail.EST_Debit ? "-" : fluxDetail.MontantTTC;
      //     fluxDetail.credit = fluxDetail.EST_Debit
      //       ? fluxDetail.MontantTTC
      //       : "-";
      //   } else {
      //     fluxDetail.debit = fluxDetail.EST_Debit ? fluxDetail.MontantTTC : "-";
      //     fluxDetail.credit = fluxDetail.EST_Debit
      //       ? "-"
      //       : fluxDetail.MontantTTC;
      //   }
      // });
      // console.log("atooooooooooo", this.context?.MKP_FluxFinancierDetail);
      return this.context?.MKP_FluxFinancierDetail?.map((fluxDetail) => ({
        ...fluxDetail,
        Debit: fluxDetail.EST_Debit ? fluxDetail.MontantTTC : "-",
        Credit: fluxDetail.EST_Debit ? "-" : fluxDetail.MontantTTC,
        PrixUnit: fluxDetail.PrixUnitaire || "-",
        Quantity: fluxDetail.Quantite || "-",
        creation: this.$moment(fluxDetail.Z_DateCreation).format("DD/MM/YYYY"),
      }));
    },

    isSuperAdmin() {
      let role = this.getRole.SlugRole;
      console.log("role ------------------", role);
      if (this.getRole.SlugRole === "super_administrateur") return true;
      return false;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.modal__voyage__detail header {
  display: flex;
  justify-content: center;
}
.close-icon {
  justify-self: flex-end;
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.info__container {
  margin: 20px 40px;
  padding: 40px;
}
</style>
<style lang="scss">
.toolbar-header {
  div {
    position: unset !important;
  }
}
</style>