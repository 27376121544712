<template>
  <div class="table__structure_list">
    <v-data-table
      :headers="headers"
      :fixed-header="true"
      :loading="loading"
      :items="items"
      item-key="id"
      :class="{
        'filters-opened': areFiltersOpened,
        'elevation-1 list__table': scrollTable,
      }"
      :footer-props="{
        'items-per-page-options': perPageOption,
      }"
      disable-sort
    >
      <template #item.Licencié="{ item }">
        <span
          v-if="item && item.UTI_Utilisateur"
          v-text="
            `${item.UTI_Utilisateur.CT_Nom} ${item.UTI_Utilisateur.CT_Prenom}`
          "
        />
        <v-chip v-else color="grey" text-color="white" v-text="'N.C'" />
      </template>
      <template #item.LicenceNumero="{ item }">
        <v-chip
          :color="
            item.UTI_Utilisateur && item.UTI_Utilisateur.LicenceNumero
              ? 'primary'
              : 'grey'
          "
          text-color="white"
          v-text="
            item.UTI_Utilisateur && item.UTI_Utilisateur.LicenceNumero
              ? item.UTI_Utilisateur.LicenceNumero
              : 'N.C'
          "
        />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom v-if="item.MKP_FluxFinancierDetail.length > 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1"
              v-on="on"
              v-bind="attrs"
              fab
              x-small
              color="green"
              outlined
              @click.prevent="visualizeFluxFinancierDetail(item)"
              key="edit"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>Visualiser les détails du flux financier</span>
        </v-tooltip>
        <!-- <span>
          <v-tooltip
            v-if="item.ID_CategoryFlux === 2 && !item.EST_Prelever"
            top
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                class="mx-1"
                fab
                x-small
                outlined
                color="green"
                @click="relaunch(item)"
              >
                <v-icon>mdi-content-duplicate</v-icon>
              </v-btn>
            </template>
            <span>Relance de prélèvement</span>
          </v-tooltip>
        </span> -->
      </template>
      <template v-slot:item.factures="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1"
              v-on="on"
              v-bind="attrs"
              fab
              x-small
              color="light-blue"
              outlined
              @click.prevent="printFacture(item)"
              key="edit"
            >
              <v-icon>mdi-file-cloud</v-icon>
            </v-btn>
          </template>
          <span>Télécharger la facture de paiement</span>
        </v-tooltip>
      </template>
      <template v-slot:item.justificatifs="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1"
              v-on="on"
              v-bind="attrs"
              fab
              x-small
              color="light-blue"
              outlined
              @click.prevent="printJustificatif(item, structure)"
              key="edit"
            >
              <v-icon>mdi-file-cloud</v-icon>
            </v-btn>
          </template>
          <span>Télécharger le justificatif</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <FluxFinancierDetail
      :openModal="openDetailModal"
      :context="fluxFinancierDetails"
      :loading="loading"
      @onClose="openDetailModal = false"
    />
    <PrintFacture ref="facture" :print="print"></PrintFacture>
    <printJustificatif ref="justificatif" :print="print"></printJustificatif>
  </div>
</template>

<script>
import FluxFinancierDetail from "@/components/utils/modals/Finances/FluxFinanciersDetailsModal";
import PrintFacture from "../../../components/Structure/Finance/PrintFacture.vue";
import printJustificatif from "../../../components/Structure/Finance/PrintJustificatif.vue";
import { mapActions, mapGetters } from "vuex";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";

export default {
  components: {
    FluxFinancierDetail,
    PrintFacture,
    printJustificatif,
  },
  computed: {
    ...mapGetters("saisons", ["selectedSaison", "currentSaison"]),

    scrollTable() {
      if (this.panel === 0 || this.itemsPerPage > 10 || this.itemsPerPage == -1)
        return true;
      return false;
    },
  },
  data: () => ({
    details: {},
    openDetailModal: false,
    fluxFinancierDetails: {},
    perPageOption: [30, 60, 90, -1],
    print: {
      assurance: null,
      cm: null,
      user: null,
      licence: null,
      complementaire: null,
      options: [],
      ID_Saison: null,
    },
    structure: {},
  }),
  async created() {
    this.setData();
  },

  methods: {
    ...mapActions("structure", [
      "getStructureByID",
      "getAffiliationTempByName",
    ]),
    ...mapActions("utilisateurs", [
      "createFluxFinancierDetail",
      "updateFluxFinancierprelevement",
    ]),

    visualizeFluxFinancierDetail(item) {
      this.fluxFinancierDetails = { ...item };
      this.openDetailModal = true;
    },

    printFacture(item) {
      this.$refs.facture.printFacture(item);
    },
    async printJustificatif(item, structure) {
      console.log(item);
      if (item.NomFluxFinancier.includes("Affiliation")) {
        console.log("go ato !!!!!");
        const structureTemp = await this.getAffiliationTempByName({
          name: item.STR_Structure.NomStructure,
        });
        console.log("structureTemp !!!!", structureTemp);
        const structureMa = JSON.parse(structureTemp.ADR_Adresse);
        console.log("structure !!!!", structure);
        this.$refs.justificatif.printJustificatif(item, structure, structureMa);
      } else this.$refs.justificatif.printJustificatif(item, structure);
    },

    async setData() {
      this.structure = await this.getStructureByID(1318);
      console.log("this.structure ", this.structure);
    },

    async relaunch(item) {
      let today = new Date();

      const newDate = new Date(today.setMonth(today.getMonth() + 1));

      item.MKP_FluxFinancierDetail.map((detail) => {
        detail.Z_DateCreation = newDate;
        delete detail.__typename;
        delete detail.id;
      });

      const fluxStructure = {
        ID_FluxFinancierDebit: 0,
        ID_Saison: this.currentSaison.id,
        ID_Structure: item.STR_Structure.id,
        ID_Traitement: 1,
        ID_Utilisateur: item.UTI_Utilisateur.id,
        MontantHT: item.MontantHT,
        MontantTTC: item.MontantTTC,
        MontantTVA: item.MontantTVA,
        EST_Debit: item.EST_Debit,
        NomFluxFinancier: item.NomFluxFinancier,
        EST_Reglement: item.EST_Reglement,
        ID_CategoryFlux: item.ID_CategoryFlux,
        Z_DateCreation: newDate,
        MKP_FluxFinancierDetail: {
          data: item.MKP_FluxFinancierDetail,
          on_conflict: {
            constraint: "MKP_FluxFinancierDetail_pkey",
            update_columns: ["ID_Tarif", "MontantHT", "MontantTTC"],
          },
        },
      };

      try {
        await this.createFluxFinancierDetail({
          flux: fluxStructure,
        });
        console.log(item.id);

        await this.updateFluxFinancierprelevement({ id: item.id });
        success_notification("Flux de prélèvement relancé");
      } catch (e) {
        error_notification("Oups, une erreur est survenue...");
      }
    },
  },

  props: {
    items: {
      type: Array,
    },

    headers: {
      type: Array,
    },
    areFiltersOpened: {
      type: Boolean,
      default: false,
    },
    panel: {
      type: Number,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.list__table .v-data-table-header {
  background: rgba(230, 230, 230, 1);
}
th {
  background: lightgrey !important;
}

.table__structure_list {
  .list__table .v-data-table__wrapper {
    height: calc(100vh - 420px);
  }
  .list__table.filters-opened .v-data-table__wrapper {
    height: calc(100vh - 500px);
  }

  &.buttonLine {
    .list__table .v-data-table__wrapper {
      height: calc(100vh - 480px);
    }
  }

  &.membresFederaux {
    .list__table .v-data-table__wrapper {
      height: calc(100vh - 310px);
    }
  }
}

/* .listSearch {
    height: calc(100vh - 370px);
    overflow: hidden;
    overflow-y: scroll;
} */
.filters-opened {
  height: calc(100vh - 440px);
}
.periode__class {
  color: lightslategray;
}
</style>
