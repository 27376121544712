<template>
  <div class>
    <v-row no-gutters>
      <SubBar
        :title="subBarTitle"
        route_name="gestion_des_finances__dashboard"
      />
    </v-row>
    <v-row>
        <v-col>
            Module en dévelopement
        </v-col>
    </v-row>

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SubBar from "@/components/Common/Common__SubBar.vue";

export default {
  components: { SubBar },

  computed: {
    subBarTitle() {
      return "Générer les virements SEPA";
    },
  },

  methods: {

  },
};
</script>

<style lang="scss" scoped>
.loading {
  background: red;
}
</style>
