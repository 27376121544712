var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table__structure_list"},[_c('v-data-table',{class:{
      'filters-opened': _vm.areFiltersOpened,
      'elevation-1 list__table': _vm.scrollTable,
    },attrs:{"headers":_vm.headers,"fixed-header":true,"loading":_vm.loading,"items":_vm.items,"item-key":"id","footer-props":{
      'items-per-page-options': _vm.perPageOption,
    },"disable-sort":""},scopedSlots:_vm._u([{key:"item.Licencié",fn:function(ref){
    var item = ref.item;
return [(item && item.UTI_Utilisateur)?_c('span',{domProps:{"textContent":_vm._s(
          ((item.UTI_Utilisateur.CT_Nom) + " " + (item.UTI_Utilisateur.CT_Prenom))
        )}}):_c('v-chip',{attrs:{"color":"grey","text-color":"white"},domProps:{"textContent":_vm._s('N.C')}})]}},{key:"item.LicenceNumero",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.UTI_Utilisateur && item.UTI_Utilisateur.LicenceNumero
            ? 'primary'
            : 'grey',"text-color":"white"},domProps:{"textContent":_vm._s(
          item.UTI_Utilisateur && item.UTI_Utilisateur.LicenceNumero
            ? item.UTI_Utilisateur.LicenceNumero
            : 'N.C'
        )}})]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(item.MKP_FluxFinancierDetail.length > 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({key:"edit",staticClass:"mx-1",attrs:{"fab":"","x-small":"","color":"green","outlined":""},on:{"click":function($event){$event.preventDefault();return _vm.visualizeFluxFinancierDetail(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualiser les détails du flux financier")])]):_vm._e()]}},{key:"item.factures",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({key:"edit",staticClass:"mx-1",attrs:{"fab":"","x-small":"","color":"light-blue","outlined":""},on:{"click":function($event){$event.preventDefault();return _vm.printFacture(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-cloud")])],1)]}}],null,true)},[_c('span',[_vm._v("Télécharger la facture de paiement")])])]}},{key:"item.justificatifs",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({key:"edit",staticClass:"mx-1",attrs:{"fab":"","x-small":"","color":"light-blue","outlined":""},on:{"click":function($event){$event.preventDefault();return _vm.printJustificatif(item, _vm.structure)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-cloud")])],1)]}}],null,true)},[_c('span',[_vm._v("Télécharger le justificatif")])])]}}])}),_c('FluxFinancierDetail',{attrs:{"openModal":_vm.openDetailModal,"context":_vm.fluxFinancierDetails,"loading":_vm.loading},on:{"onClose":function($event){_vm.openDetailModal = false}}}),_c('PrintFacture',{ref:"facture",attrs:{"print":_vm.print}}),_c('printJustificatif',{ref:"justificatif",attrs:{"print":_vm.print}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }