<template>
  <div>
    <v-row no-gutters>
      <SubBar
        :title="subBarTitle"
        route_name="gestion_des_finances__dashboard"
      />
    </v-row>

    <v-row v-if="mounted" no-gutters class="informationsdemonclub__layout">
      <v-col cols="12" color="blue" sm="4" lg="3">
        <v-row class="informationsdemonclub__sidebar block" no-gutters>
          <v-col cols="12" class="informationsdemonclub__sidebar-avatar py-4">
            <Avatar
              :context="{ ID_Structure: currentStructureId }"
              size="102"
            />
          </v-col>

          <v-col cols="12" class="informationsdemonclub__sidebar-navigation">
            <Navigation></Navigation>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" sm="8" lg="9" class="informationsdemonclub__content">
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Avatar from "@/components/Common/Common__Avatar.vue";
import SubBar from "@/components/Common/Common__SubBar.vue";
import Navigation from "@/components/GestionsDesFinances/ParametragesComptables__Navigation.vue";

export default {
  data: () => ({
    subBarTitle: "Paramétrages Comptables",
    mounted: false,
    isActive: false,
  }),

  components: { Avatar, SubBar, Navigation },

  created() {
    this.mounted = true;
  },

  computed: {
    ...mapGetters("structure", ["currentStructureId"]),
  },
};
</script>

<style lang="scss">
.test__dematerialise {
  padding: 0px !important;
}
</style>
