<template>
  <v-row
    no-gutters
    class="gestion_des_finances__dashboard-layout"
    :class="{ loading: loading }"
  >
    <v-col cols="12" lg="8" class="mx-auto">
      <v-row style="height: 100%;">
        <v-col cols="12" class="p-4 licences-adherents-content">
          <v-row style="height: 100%;">
            <v-col cols="6">
              <dashboardCard
                title_card="Paramétrages comptable"
                module_name="gestion_des_finances__parametrage_plan_comptable"
                isComingSoon
                auth
              />
            </v-col>

            <!-- <v-col cols="6">
              <dashboardCard
                title_card="Exporter vers Sage"
                module_name="gestion_des_finances__export_sage"
              />
            </v-col>

            <v-col cols="6">
              <dashboardCard
                title_card="Générer les virements SEPA"
                module_name="gestion_des_finances__generer_virements_sepa"
              />
            </v-col> -->

            <v-col cols="6">
              <dashboardCard
                title_card="Recherche flux financiers"
                auth
                :custom_link="linkFinancialFluxes"
                target="_self"
              />
            </v-col>

            <v-col cols="6">
              <dashboardCard
                title_card="SAGE/SEPA"
                module_name="export__sepa"
              />
            </v-col>

            <v-col cols="6">
              <dashboardCard
                title_card="Création factures"
                module_name="gestion_des_finances__etats_statistiques"
                isComingSoon
                auth
              />
            </v-col>
            <v-col cols="6" v-if="currentStructureId === 1318">
              <dashboardCard
                title_card="RIB"
                module_name="gestion_des_finances__rib"
              />
            </v-col>
            <v-col cols="6">
              <dashboardCard
                title_card="Récapitulatif mensuel "
                module_name="gestion_des_finances__recapitulatif"
                isComingSoon
                auth
              />
            </v-col>
            <!-- <v-col cols="6">
              <dashboardCard
                title_card="Etats statistiques"
                module_name="gestion_des_finances__etats_statistiques"
              />
            </v-col> -->
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DashboardCard from '@/components/Common/DashboardCard';
export default {
  name: 'GestionDesFinances__Dashboard',

  components: { DashboardCard },

  data: () => ({
    loading: true,
  }),
  computed: {
    ...mapGetters('structure', ['currentStructureId']),
    linkFinancialFluxes() {
      return `${process.env.VUE_APP_FRONT_NEXT_URL}/gestion-des-finances/flux-financiers?structureId=${this.currentStructureId}&appMode=${localStorage.getItem("ffme_user_mode")}`;
    },
  },
  async created() {
    await this.setAppTitle({ title: 'Gestion des finances' });
    this.loading = false;
  },

  methods: {
    ...mapActions('app', ['setAppTitle']),
  },
};
</script>

<style lang="scss">
.gestion_des_finances__dashboard-layout {
  position: relative;
  height: calc(100vh - 80px);
  &.loading {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
    }
  }
}
</style>
