<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span v-if="panel === 0">Masquer les filtres</span>
              <span v-else>Afficher les filtres</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content color="#f0f0f0">
              <ValidateForm :showFieldObl="false">
                <v-row class="pt-5">
                  <v-col
                    cols="1"
                    lg="2"
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    <inputText
                      v-model="search.structureCode"
                      rules="min:3"
                      placeholder="Code structure"
                      @keydown.enter="sendRequest"
                    ></inputText>
                  </v-col>
                  <v-col
                    cols="1"
                    lg="2"
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    <selectInput
                      v-model="structureSelected"
                      :items="structureList"
                      item_text="NomStructure"
                      item_value="id"
                      placeholder="Structure"
                      :loading="isLoadingStructureList"
                      :disabled="structureDisabled"
                      return-object
                    />
                  </v-col>
                  <v-col
                    cols="2"
                    lg="2"
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    <inputText
                      v-model="search.licenceNumero"
                      rules="min:3"
                      placeholder="N° Licence"
                      @keydown.enter="sendRequest"
                    ></inputText>
                  </v-col>
                  <v-col
                    cols="2"
                    lg="3"
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    <inputText
                      v-model="search.libelle"
                      rules="min:3"
                      placeholder="Libellé flux financier"
                      @keydown.enter="sendRequest"
                    ></inputText>
                  </v-col>

                  <v-col
                    cols="2"
                    lg="3"
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    <inputText
                      v-model="search.Nom"
                      rules="min:3"
                      placeholder="Nom licencié"
                      @keydown.enter="sendRequest"
                    ></inputText>
                  </v-col>
                  <v-col
                    cols="2"
                    lg="2"
                    xl="2"
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    <DatePicker
                      v-model="search.dateDebut"
                      rules="min:3"
                      :maxToday="true"
                      placeholder="Date début"
                    ></DatePicker>
                  </v-col>
                  <v-col
                    cols="2"
                    lg="2"
                    xl="2"
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    <DatePicker
                      v-model="search.dateFin"
                      rules="min:3"
                      :maxToday="true"
                      placeholder="Date de fin"
                    ></DatePicker>
                  </v-col>

                  <v-col
                    cols="1"
                    lg="2"
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    <selectInput
                      v-model="Saison"
                      :items="allFedeSaisons"
                      item_text="NomSaison"
                      item_value="id"
                      placeholder="Saison"
                    />
                  </v-col>

                  <v-col cols="4" lg="3">
                    <v-btn
                      @click.prevent="erraseFilter()"
                      outlined
                      color="indigo"
                      >Effacer tous les filtres</v-btn
                    >
                  </v-col>

                  <v-col
                    cols="12"
                    class="pb-3"
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    <v-btn
                      class="btn btn-primary mr-4"
                      @click.prevent="sendNewSearch"
                      :loading="loading"
                      >Rechercher</v-btn
                    >
                    <v-btn
                      class="btn btn-primary"
                      @click="exportSearch"
                      :disabled="!!!(fluxFinancier && fluxFinancier.length)"
                      >Exporter la recherche</v-btn
                    >
                    <JsonExcel
                      class="d-none"
                      ref="csvBtn"
                      :data="fluxFinancier"
                      :fields="json_fields"
                      name="filename.xls"
                      >Exporter</JsonExcel
                    >
                  </v-col>
                </v-row>
              </ValidateForm>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12" lg="12">
        <FluxFinancierSearch
          :headers="tabHeaders"
          :items="fluxFinancier"
          :loading="loading"
          :panel="panel"
          :areFiltersOpened="panel === 0"
        >
        </FluxFinancierSearch>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import inputText from "@/components/utils/input";
import selectInput from "@/components/utils/select";
import ValidateForm from "@/components/utils/form";
import JsonExcel from "vue-json-excel";
import FluxFinancierSearch from "@/components/Structure/Finance/FluxFinancierSearch";

import { bus } from "@/main";

export default {
  components: {
    // subBar,
    inputText,
    ValidateForm,
    selectInput,
    JsonExcel,
    FluxFinancierSearch,
    DatePicker: () =>
      import(
        /* webpackChunkName: "input__datepicker" */ "@/components/utils/datePicker.vue"
      ),
  },

  data: () => ({
    fluxFinancier: [],
    isLoadingStructureList: true,
    structureDisabled: false,
    structureSelected: null,
    inputType1: "text",
    inputType2: "text",
    tabHeaders: [
      {
        text: "N° de flux",
        value: "id",
      },
      { text: "N° Licence", value: "LicenceNumero", sortable: false },

      {
        text: "Licencié",
        value: "Licencié",
        sortable: false,
      },
      {
        text: "Date création",
        value: "Z_DateCreation",
      },

      {
        text: "Structure",
        value: "STR_Structure.NomStructure",
        sortable: false,
      },

      {
        text: "Libellé",
        value: "NomFluxFinancier",
        sortable: false,
      },

      { text: "Débit", value: "Debit" },
      { text: "Crédit", value: "Credit" },

      {
        text: "Action",
        value: "actions",
        sortable: false,
      },

      { text: "Justificatif", value: "justificatifs" },
      // { text: "Facture", value: "factures" },
    ],
    mounted: false,
    search: {
      structureCode: "",
      structureId: "",
      licenceNumero: "",
      Nom: "",
      dateDebut: "",
      dateFin: "",
      libelle: "",
    },
    Saison: 2021,
    panel: 0,
    allFedeSaisons: [],
    voyages: [],
    loading: false,
    json_fields: {
      "N° de flux": "id",
      Structure: "STR_Structure.NomStructure",
      Libellé: "NomFluxFinancier",
      Débit: "Debit",
      Crédit: "Credit",
      "Date création": "Z_DateCreation",
    },
  }),

  async created() {
    // await this.setData();
    this.Saison = this.currentSaisonId;
    this.mounted = true;
    this.initialize();
    return;
  },

  computed: {
    ...mapGetters("structure", ["currentStructure", "hasCodeFederal"]),
    ...mapGetters("saisons", ["currentSaisonId"]),
    ...mapGetters("voyage", ["getVoyage"]),
    ...mapGetters("user", ["getRole"]),
    ...mapState({
      structureList: (state) => state.structure.listOfStructure,
    }),

    disabledSearch() {
      return !(
        this.search.structureCode !== "" ||
        this.search.structureId !== "" ||
        this.search.licenceNumero !== "" ||
        this.search.Nom !== "" ||
        this.search.libelle !== "" ||
        this.search.dateDebut !== "" ||
        this.search.dateFin !== ""
      );
    },
    whereClause() {
      let clause;
      if (this.search.licenceNumero !== "") {
        clause = {
          ...clause,
          UTI_Utilisateur: {
            LicenceNumero: { _eq: this.search.licenceNumero },
          },
        };
      }
      if (this.search.Nom !== "") {
        clause = {
          ...clause,
          UTI_Utilisateur: {
            CT_Nom: { _ilike: `%${this.search.Nom}%` },
          },
        };
      }

      if (this.search.structureCode !== "") {
        clause = {
          ...clause,
          STR_Structure: { ID_CodeFederal: { _eq: this.search.structureCode } },
        };
      }
      if (this.search.libelle !== "") {
        clause = {
          ...clause,
          NomFluxFinancier: { _ilike: `%${this.search.libelle}%` },
        };
      }
      // parametrage du perimetre de recherche
      if (this.structureSelected) {
        clause = {
          ...clause,
          STR_Structure: {
            id: { _eq: this.structureSelected?.id },
          },
        };
      }
      // all club
      else if (this.currentStructure?.STR_Structure?.ID_TypeStructure !== 7) {
        clause = {
          ...clause,
          STR_Structure: {
            id: { _eq: this.currentStructure?.STR_Structure?.id },
          },
        };
      }
      // ffme
      else {
        clause = {
          ...clause,
        };
      }

      if (this.search.dateDebut !== "" && this.search.dateFin !== "") {
        if (this.search.dateDebut === this.search.dateFin) {
          const dateFin = this.$moment(this.search.dateFin).toISOString();
          const newDateFin = this.$moment(dateFin).add(1, "days");
          return {
            ...clause,
            Z_DateCreation: {
              _gte: this.$moment(this.search.dateDebut).toISOString(),
              _lte: this.$moment(newDateFin).toISOString(),
            },
          };
        } else {
          const date = new Date(this.search.dateFin).getDate();

          return {
            ...clause,
            Z_DateCreation: {
              _gte: this.$moment(this.search.dateDebut).toISOString(),
              _lte: this.$moment(
                new Date(new Date(this.search.dateFin).setDate(+date + 1))
              ).toISOString(),
            },
          };
        }
      }
      if (this.search.dateDebut !== "") {
        clause = {
          ...clause,
          Z_DateCreation: {
            _gte: this.$moment(this.search.dateDebut).toISOString(),
          },
        };
      }
      if (this.search.dateFin !== "") {
        const date = new Date(this.search.dateFin).getDate();
        return {
          ...clause,
          Z_DateCreation: {
            _lte: this.$moment(
              new Date(new Date(this.search.dateFin).setDate(+date + 1))
            ).toISOString(),
          },
        };
      }

      return clause;
    },

    isSuperAdmin() {
      let role = this.getRole.SlugRole;
      console.log("role ------------------", role);
      if (this.getRole.SlugRole === "super_administrateur") return true;
      return false;
    },
  },

  methods: {
    ...mapActions("saisons", ["getSaisons"]),
    ...mapActions("voyage", ["getVoyagesFiltered"]),
    ...mapActions("finances", ["queryFluxFinanciers"]),
    ...mapActions("structure", ["getStructureListBySpecificInput"]),

    setToDate() {
      this.inputType = "date";
    },
    erraseFilter() {
      this.search = {
        structureCode: "",
        licenceNumero: "",
        Nom: "",
        dateDebut: "",
        dateFin: "",
        libelle: "",
      };
      const structureIDs = [4, 5, 7]; // CT,ligue/federation
      if (
        structureIDs.includes(
          this.currentStructure?.STR_Structure?.ID_TypeStructure
        )
      ) {
        this.structureSelected = null;
      }
      bus.$emit("resetDatePicker");
      this.fluxFinancier = [];
    },
    async initialize() {
      switch (this.currentStructure?.STR_Structure?.ID_TypeStructure) {
        //FFME
        case 7:
          {
            const whereClause = {
              ID_Federation: { _eq: this.currentStructure?.STR_Structure?.id },
            };
            await this.getStructureListBySpecificInput({ whereClause });
            this.isLoadingStructureList = false;
          }
          break;
        // club and other more
        default:
          {
            const whereClause = {
              id: { _eq: this.currentStructure?.STR_Structure?.id },
            };
            await this.getStructureListBySpecificInput({ whereClause });
            this.structureSelected = {
              ...this.currentStructure?.STR_Structure,
            };
            this.isLoadingStructureList = false;
            this.structureDisabled = true;
          }
          break;
      }
    },

    async sendRequest() {
      if (!this.disabledSearch) return await this.sendNewSearch();
    },
    async sendNewSearch() {
      await this.setData();
      this.loading = false;
    },
    async setData() {
      this.loading = true;
      this.allFedeSaisons = await this.getSaisons();
      const date = new Date(this.search.dateFin).getDate();
      const final = new Date(new Date(this.search.dateFin).setDate(+date + 1));
      const response = await this.queryFluxFinanciers({
        whereClause: this.whereClause,
      });
      // response.map((flux) => {
      //   if (this.isSuperAdmin) {
      //     flux.debit = flux.EST_Debit ? "-" : flux.MontantTTC;
      //     flux.credit = flux.EST_Debit ? flux.MontantTTC : "-";
      //   } else {
      //     flux.debit = flux.EST_Debit ? flux.MontantTTC : "-";
      //     flux.credit = flux.EST_Debit ? "-" : flux.MontantTTC;
      //   }
      // });
      console.log("ato :>> ");
      this.fluxFinancier = response?.map((flux) => ({
        ...flux,

        Z_DateCreation: this.$moment(flux.Z_DateCreation).format("DD/MM/YYYY"),
        PrixUnitaire: flux.MKP_FluxFinancierDetail[0]?.PrixUnitaire || "-",
        Quantite: flux.MKP_FluxFinancierDetail[0]?.Quantite || "-",
        Debit: flux.EST_Debit ? flux.MontantTTC : "-",
        Credit: flux.EST_Debit ? "-" : flux.MontantTTC,
      }));
      console.log("this.fluxFinancier :>> ", this.fluxFinancier);
    },

    async exportSearch() {
      this.loading = true;
      setTimeout(() => {
        this.$refs.csvBtn.$el.click();
      }, 100);
      this.loading = false;
    },
  },
};
</script>

<style>
.v-text-field__details {
  display: none;
}

.v-text-field__slot input {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
