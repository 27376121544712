<template>
  <div id="print-justificatif" ref="print-justificatif">
    <div class="container">
      <img class="habillage" src="~@/assets/FFME_EN_TETE.jpg" alt="" />
      <div class="header">
        <h1 class="d-flex justify-content-center text-transform-uppercase">
          RELEVÉ D'OPÉRATION
        </h1>
        <p class="adresses" style="padding-top: 40px;">
          Siège national
        </p>
        <p class="adresses">
          {{ adresseSN }}
        </p>
        <p class="adresses">
          {{ villeSN }}
        </p>
        <div
          class="table-contact d-flex justify-content-center"
          style="position: absolute; top: 30vh; width: 970px;"
        >
          <table>
            <tr>
              <th style="text-align: center;">Date</th>
              <th style="text-align: center;">Numéro d'opération</th>
              <th style="text-align: center;">Référence</th>
            </tr>
            <tr>
              <td style="text-align: center;">{{ date }}</td>
              <td style="text-align: center;">
                {{ numero }}
              </td>
              <td style="text-align: center;">
                STRUCTURE N° {{ structureId }}
              </td>
            </tr>
          </table>
        </div>
        <div class="text-details">
          <p style="font-weight: bold;">Libellé de l'opération :</p>
          <p>{{ designation }}</p>
          <p style="font-weight: bold;">Montant :</p>
          <p>{{ total.toFixed(2) }} €</p>
          <p style="font-weight: bold;">Compte débiteur :</p>
          <p>{{ nomDebiteur }}</p>
          <p>
            {{ adresseDebiteur }}
          </p>
          <p v-if="data.STR_Structure">
            {{ structureCodepostal }}
          </p>
          <p style="font-weight: bold;">Compte créditeur :</p>
          <p>{{ nomCrediteur }}</p>
          <p>{{ adresseCrediteur }}</p>
          <p>
            {{ villeCrediteur }}
          </p>
          <p style="font-weight: bold;">Pour tout contact :</p>
          <p>
            Service vie territoriale : 01.40.18.75.51 -
            <a href="info@ffme.fr">info@ffme.fr</a>
          </p>
        </div>
      </div>
    </div>
    <div class="generate">
      <p>Document généré le {{ $moment().format("DD/MM/YYYY") }}</p>
      <p>Justificatif valant facture</p>
    </div>
  </div>
</template>
<script>
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
export default {
  data: () => ({
    data: Object,
    date: Object,
    numero: "",
    structure: {},
    structureId: 0,
    designation: "",
    total: 0,
    adresseSN: "",
    villeSN: "",
    structureSN: "",
    nomCrediteur: "",
    adresseCrediteur: "",
    villeCrediteur: "",
    nomDebiteur: "",
    adresseDebiteur: "",
    structureCodepostal: "",
  }),
  props: {
    print: {
      type: Object,
    },
  },
  methods: {
    printJustificatif(item, structure, structureMa) {
      console.log("item", item);
      console.log("structure", structure);
      this.data = item;
      this.structure = structure;
      this.numero = item.id;
      // this.date = this.$moment(item.Z_DateCreation).format("DD/MM/YYYY");
      this.date = item.Z_DateCreation;
      this.structureId = item.STR_Structure?.id;
      this.designation = item.NomFluxFinancier;

      if (item.NomFluxFinancier.includes("Affiliation")) {
        this.nomDebiteur =
          this.data.STR_Structure && this.data.STR_Structure.NomStructure;
        this.structureCodepostal =
          structureMa.CodePostal + " " + structureMa.country;
        this.adresseDebiteur = structureMa.adress;

        this.nomCrediteur = "Siège national";
        this.adresseCrediteur = structure.ADR_Adresse?.Adresse1;
        this.villeCrediteur =
          structure.ADR_Adresse?.CodePostal +
          " " +
          structure.ADR_Adresse?.Ville;
      } else {
        // this.designation = "Achat " + item.NomFluxFinancier;
        if (!this.data.EST_Debit) {
          this.adresseDebiteur = this.data.UTI_Utilisateur.ADR_Adresse[0]
            ? this.data.UTI_Utilisateur.ADR_Adresse[0].Adresse1
            : "";
          this.nomDebiteur =
            this.data.UTI_Utilisateur.CT_Prenom +
            " " +
            this.data.UTI_Utilisateur.CT_Nom;

          this.structureCodepostal = this.data.UTI_Utilisateur.ADR_Adresse[0]
            ? this.data.UTI_Utilisateur.ADR_Adresse[0].CodePostal +
              " " +
              this.data.UTI_Utilisateur.ADR_Adresse[0].Ville
            : "";

          this.nomCrediteur = "Siège national";
          this.adresseCrediteur = structure.ADR_Adresse?.Adresse1;
          this.villeCrediteur =
            structure.ADR_Adresse?.CodePostal +
            " " +
            structure.ADR_Adresse?.Ville;
        } else {
          this.nomDebiteur = "Siège national";
          this.adresseDebiteur = structure.ADR_Adresse?.Adresse1;
          this.structureCodepostal =
            structure.ADR_Adresse?.CodePostal +
            " " +
            structure.ADR_Adresse?.Ville;

          this.nomCrediteur =
            this.data.UTI_Utilisateur.CT_Prenom +
            " " +
            this.data.UTI_Utilisateur.CT_Nom;
          this.adresseCrediteur = this.data.UTI_Utilisateur.ADR_Adresse[0]
            ? this.data.UTI_Utilisateur.ADR_Adresse[0].Adresse1
            : "";
          this.villeCrediteur = this.data.UTI_Utilisateur.ADR_Adresse[0]
            ? this.data.UTI_Utilisateur.ADR_Adresse[0].CodePostal +
              " " +
              this.data.UTI_Utilisateur.ADR_Adresse[0].Ville
            : "";
        }
      }

      this.adresseSN = structure.ADR_Adresse?.Adresse1;
      this.villeSN =
        structure.ADR_Adresse?.CodePostal + " " + structure.ADR_Adresse?.Ville;
      this.total = item.MontantTTC;

      const name = "Justificatif " + this.numero + "-" + this.date;

      this.$nextTick(() => {
        this.$refs["print-justificatif"].style.display = "block";
        let source = document.querySelector("#print-justificatif");
        html2canvas(source, {
          letterRendering: 1,
          scale: 2,
          logging: true,
          useCORS: true,
        }).then((canvas) => {
          const imgData = canvas.toDataURL("image/jpeg");
          const pdf = new jsPDF({
            orientation: "portrait",
          });
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
          pdf.save(name);
          this.$refs["print-justificatif"].style.display = "none";
        });
      });
    },
  },
};
</script>
<style lang="scss">
#print-justificatif {
  width: 1000px;
  position: absolute;
  z-index: -3;
  top: 0;
  left: 0;
  display: none;
  .container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .habillage {
    width: 100%;
  }
  .header {
    width: 100%;
    position: absolute;
    top: 10vh;
    h1 {
      font-size: 50px;
      color: rgb(172, 169, 169);
      display: flex;
      justify-content: center;
      padding-left: 50px;
    }
    .adresses {
      display: flex;
      justify-content: flex-end;
      padding-right: 250px;
      font-size: 18px;
      color: black;
    }
    table,
    th,
    td {
      border: 1px solid black;
      border-collapse: collapse;
      width: 900px;
      font-size: 16px;
    }
  }
  .table-contact {
    position: absolute;
  }
  .text-details {
    position: absolute;
    top: 40vh;
    font-size: 18px;
    margin-left: 40px;
    color: black;
  }
  .generate {
    position: absolute;
    bottom: 230px;
    right: 100px;
    align-self: end;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
      font-style: italic;
      color: black;
      font-size: 18px;
    }
  }
}
</style>
