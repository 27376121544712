<template>
  <div class>
    <v-row no-gutters>
      <SubBar
        :title="subBarTitle"
        :route_name="route_name"
      />
    </v-row>
    <FluxFinanciers />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SubBar from "@/components/Common/Common__SubBar.vue";
import FluxFinanciers from "@/views/GestionDesFinances/Flux_financiers/FluxFinancier";
export default {
  components: { SubBar, FluxFinanciers },
  data: () => ({
    route_name : "",
  }),

  async created() {
    const url = this.$route.path.split('/');
    const route = url[1];
    if(route == "gestion-des-finances") this.route_name = "gestion_des_finances__dashboard";
    else if(route == "gestion-comptabilite") this.route_name = "gestion_comptabilite__dashboard";
  },

  computed: {
    subBarTitle() {
      return "Rechercher les flux financiers";
    },
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.loading {
  background: red;
}
</style>
